
const FirstStep = () => import('./FirstStep');
const SecondStep = () => import('./SecondStep');
const ButtonsSection = () => import('./ButtonsSection');

const DatingSteps = () => import('./DatingSteps');
const LocatorSteps = () => import('./LocatorSteps');
const TinderSteps = () => import('./TinderSteps');

export default {
  name: 'Steps',
  components: { FirstStep, SecondStep, ButtonsSection, DatingSteps, LocatorSteps, TinderSteps },

  data() {
    return {}
  },
  computed: {
    shortPlan() {
      return this.$store.state.selectedPlan === 0 ||
        this.$store.state.selectedPlan === 1 ||
        this.$store.state.selectedPlan === 3 ||
        this.$store.state.selectedPlan === 4;
    }
  }
}
